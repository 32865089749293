.game-board-container {
  display: flex;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background-color: var(--primary);
}
.game-board-container .info-wrapper {
  flex: 3;
  min-width: 20rem;
  background-color: var(--primary);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.game-board-container .info-container {
}
.game-board-container .info-container .header {
  padding: 2rem 1rem;
  text-align: center;
}
.game-board-container .info-container .header h2 {
  font-size: 1.4rem;
  margin: 0;
  color: var(--font2-color);
}
.game-board-container .info-footer {
  padding: 2rem 0;
  text-align: center;
}
.game-board-container .info-footer .next-game {
  font-size: 1.3rem;
  padding: 0.5rem 0.75rem;
  transition: 0.5s ease-in-out;
}
.game-board-container .game-container {
  position: relative;
  flex: 15;
}
.player-container {
  margin-bottom: 1rem;
}
.player-container h3 {
  font-size: 1.3rem;
  margin: 0;
  padding: 1rem 0.5rem;
  color: var(--font2-color);
}
.player-container .score-container {
  padding: 0.5rem;
}

.player-container .score-container h4 {
  font-size: 1.1rem;
  margin: 0;
  color: var(--font2-color);
}

.loading-overlay {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: var(--primary);
  z-index: 100;
  opacity: 0;
  transition: 1s ease-in-out;
}
.loading-overlay-container {
  text-align: center;
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
}
.loading-overlay-message {
  font-size: 3rem;
  font-weight: bold;
  color: var(--font2-color);
}
.game-round-timer {
  position: absolute;
  color: var(--font2-color);
  font-size: 2rem;
  background-color: var(--primary);
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  padding: 0.75rem 1rem;
  width: 5rem;
  text-align: center;
  border-radius: 0 0 2rem 2rem;
  z-index: 1;
}
.game-round-timer .small-loading-eath {
  font-size: 1.8rem;
}
.game-board-map-container {
  display: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
}
.round-image-container {
  position: absolute;
  z-index: 2;
  text-align: center;
  border-radius: 1rem;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  background-color: #ffffff;
  overflow: hidden;
  opacity: 0;
}
.round-image-container-large {
  width: 45%;
  min-width: 20rem;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 55%);
  -webkit-box-shadow: 0 0.175rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
  box-shadow: 0 0.175rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
}
.round-image-container-small {
  width: 25%;
  right: 3%;
  bottom: 3%;
  transform: translate(0, 0) rotateY(10deg) rotateX(-12deg) rotateZ(2.5deg);
  -webkit-box-shadow: 0.3rem 0.2rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
  box-shadow: 0.3rem 0.2rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
}
.round-image-container img {
  position: relative;
  width: 98%;
  margin: 1%;
  border-radius: 1rem;
}
.round-image-container div {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 1rem 0 1rem 0;
  padding: 1rem 2rem;
}
.round-image-container div h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.2rem;
  color: var(--primary);
}
.round-image-container div h3 {
  margin: 0 0 0.5rem 0;
}
.round-image-container-large div h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.6rem;
}
.round-image-container-small div h3 {
  margin: 0 0 0.5rem 0;
  font-size: 0.9rem;
}
.top-guesses-container {
  position: absolute;
  z-index: 1;
  bottom: -10rem;
  left: 2rem;
  transition: 0.7s ease-in-out;
  background-color: var(--primary);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transform: translate(0, 0) rotateY(10deg) rotateX(12deg) rotateZ(-2.5deg);
  -webkit-box-shadow: -0.3rem 0.2rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
  box-shadow: -0.3rem 0.2rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
}
.top-guesses-container ul {
  padding: 0;
  list-style: none;
}
.top-guesses-container ul li {
  font-size: 1.2rem;
  margin: 2rem 0 2rem 3rem;
  color: var(--font2-color);
}
.top-guesses-container ul li label {
  position: absolute;
  font-size: 2.2rem;
  margin-left: -3.5rem;
  margin-top: -1rem;
  font-weight: bold;
}

.top-guesses-container ul li span {
  margin-left: 0.5rem;
  font-weight: bold;
}
