.reconnect-conatiner {
  position: fixed;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  background-color: #000;
}
.reconnect-header {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.reconnect-header h1,
.reconnect-header h2 {
  color: var(--font2-color);
  margin: 0;
}
.reconnect-header h1 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.reconnect-header h2 {
  font-size: 1.6rem;
}
.reconnect-content {
  flex: 7;
  position: relative;
}
.reconnect-content .earth {
  font-size: 6rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.reconnect-content .earth img {
  width: 10rem;
}
.reconnect-content .satelite {
  position: absolute;
  margin-left: 50%;
  margin-top: 50%;
}
.reconnect-content .satelite img {
  width: 2.5rem;
  position: absolute;
}
.reconnect-footer {
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
}
.reconnect-footer div {
  display: flex;
  justify-content: space-evenly;
  height: 3rem;
}
