.gg-lobby-container > * {
  overflow: hidden;
}
.gg-overlay-bkg {
  position: fixed;
  left: -1vw;
  top: -1vh;
  width: 102vw;
  height: 102vh;
  background-color: #22222299;
  z-index: 0;
}
.gg-main-btn {
  font-size: 1.4rem;
  border: none;
  padding: 0.6rem 1.3rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  background-color: var(--accent);
  color: var(--font2-color);
  -webkit-box-shadow: 0 0.175rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
  box-shadow: 0 0.175rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
}
.gg-lobby-container,
.gg-create-game-container,
.gg-join-game-container {
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
}
.gg-lobby-container .gg-lobby-btn {
  margin: 2rem auto 2rem auto;
  justify-content: center;
}
.gg-lobby-container .gg-lobby-btn-hover {
  opacity: 0.7;
}
/* SHADOW*/
.gg-main-btn,
.gg-game-list-item,
.gg-create-game-container .gg-game-code,
.gg-join-game-container .gg-join-game-form input {
  -webkit-box-shadow: 0 0.15rem 0.75rem -0.05rem var(#111);
  box-shadow: 0 0.15rem 0.75rem -0.05rem var(#111);
}
.gg-create-game-container {
  flex-direction: row;
}
.gg-create-game-container .gg-create-game-left-panel,
.gg-create-game-container .gg-create-game-right-panel {
  display: flex;
  flex: 1;
}

.gg-create-game-container .gg-create-game-left-panel,
.gg-create-game-container .gg-create-game-right-panel {
  padding: 2rem;
  flex-direction: column;
}
.gg-create-game-container .gg-create-game-left-panel-top {
  flex: 1;
  position: relative;
  text-align: center;
}
.gg-create-game-container .gg-create-game-left-panel h2,
.gg-create-game-container .gg-create-game-right-panel h2 {
  color: var(--font2-color);
  font-size: 1.6rem;
  margin: 0;
}
.gg-create-game-container .gg-game-code {
  background-color: var(--font2-color);
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  display: inline-flex;
  margin: auto;
  border-radius: 0.5rem;
  margin-top: 2rem;
}
.gg-create-game-container .qr-code {
  border: 1px solid red;
  display: block;
  margin: 1rem auto 2rem auto;
  height: 8rem;
  width: 8rem;
  border: 0.5rem solid var(--font2-color);
  border-radius: 1rem;
  background-color: var(--font2-color);
  padding: 0;
}

.gg-create-game-container .gg-create-game-left-panel-mid {
  flex: 3;
  overflow-y: auto;
}
.gg-create-game-container .gg-create-game-left-panel-mid ul {
  list-style: none;
  padding: 0;
}
.gg-create-game-container .gg-create-game-left-panel-mid ul li {
  color: var(--font2-color);
  font-size: 1.2rem;
  margin: 1rem 0;
}
.gg-create-game-container .gg-create-game-left-panel-bottom {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.gg-create-game-container .gg-create-game-left-panel-bottom .gg-start-btn,
.gg-create-game-container .gg-create-game-left-panel-bottom .gg-to-lobby-btn {
  margin: 1rem auto;
}
.gg-create-game-container .gg-create-game-right-panel-body {
  flex: 4;
  overflow-y: auto;
  margin-top: 1rem;
}
/* width */
.gg-create-game-container ::-webkit-scrollbar {
  width: 0.5rem;
}
/* Track */
.gg-create-game-container ::-webkit-scrollbar-track {
  background: #666;
  border-radius: 0.5rem;
}

/* Handle */
.gg-create-game-container ::-webkit-scrollbar-thumb {
  background: #eee;
  border-radius: 0.5rem;
}

/* Handle on hover */
.gg-create-game-container ::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}
.gg-game-list-item {
  margin: 3rem 1rem;
  padding: 1.5rem;
  background-color: var(--primary);
  border-radius: 1rem;
  opacity: 0.85;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  border: 0.2rem solid transparent;
}
.gg-game-list-item:hover,
.gg-game-list-item-selected {
  opacity: 1;
  cursor: pointer;
}
.gg-game-list-item-selected {
  border-color: var(--accent);
}
.gg-game-list-item .game-title,
.gg-game-list-item .game-rounds,
.gg-game-list-item .game-description {
  color: var(--font2-color);
  padding: 0;
  margin: 0;
}
.gg-game-list-item .game-title {
  margin-bottom: 0.75rem;
  font-size: 1.5rem;
}
.gg-game-list-item .game-rounds {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
.gg-game-list-item .game-description {
  font-size: 1.15rem;
}
.gg-create-game-right-panel-body .err-none-found {
  color: var(--font2-color);
  font-size: 1.2rem;
}
.gg-join-game-container .gg-join-game-wrapper {
  position: relative;
  text-align: center;
  padding: 1rem;
}

.gg-join-game-container .gg-join-game-wrapper label {
  font-size: 1.7rem;
  color: var(--font2-color);
  margin: 0;
  display: block;
  margin-bottom: 0.25rem;
}
.gg-join-game-container .gg-join-game-wrapper input {
  font-size: 1.4rem;
  width: 18rem;
  margin-bottom: 1.75rem;
  height: 2.25rem;
  border: none;
  border-radius: 0.5rem;
  outline: none;
  padding: 0.3rem;
  text-align: center;
}
.gg-join-game-container .gg-join-game-wrapper .gg-ok-join-game {
  margin-top: 1rem;
  margin-bottom: 1.75rem;
}
.gg-join-game-container .gg-join-game-wrapper .gg-to-lobby {
}
