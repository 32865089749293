:root {
  --font1-color: #000;
  --font2-color: #fff;
  --accent2: #e96535;
  --accent: #5eac76;
  --primary: #23282e;
  --primary-hover: #353c46;
  --error: #e84855;
  --warning: #e09600;
  --success: #71b143;
}

body {
  font-family: sans-serif;
  background-color: #fff;
  color: var(--font1-color);
  margin: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

html {
  font-size: 16px;
}

a {
  color: var(--font2-color);
  text-decoration: none;
}

.bkg-video {
  position: fixed;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: -1;
}

.card-shadow {
  -webkit-box-shadow: 0 0.175rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
  box-shadow: 0 0.175rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
}
.alert-container {
  position: fixed;
  text-align: center;
  left: 50%;
  width: 0;
  height: 0;
  transform: translateX(-50%);
  overflow-y: visible;
  margin-top: 1rem;
}
.alert-item {
  color: var(--font2-color);
  background-color: var(--primary);
  display: inline-block;
  padding: 0.35rem 1rem;
  border-radius: 0.75rem;
  margin: 0.45rem 0;
  transform: translateX(-50%);
  transition: 0.3s;
  -webkit-transition: 0.3s;
}
.alert-item-type-error {
  background-color: var(--error);
}
.alert-item-type-warning {
  background-color: var(--warning);
}
.alert-item-type-success {
  background-color: var(--success);
}
.alert-message {
  color: var(--font2-color);
  margin: 0;
  font-size: 1.5rem;
  white-space: nowrap;
}
.no-highlight {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.loading-container {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.loading-message {
  flex: 1;
  font-size: 2rem;
  color: var(--font2-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loading-icon-wrapper {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loading-icon-container {
  display: flex;
  justify-content: center;
}
.loading-icon {
  position: relative;
  width: 15rem;
  margin-top: -15%;
}
.rules-wrapper {
  position: absolute;
  background: #222222aa;
  width: 100%;
  height: 100%;
  z-index: 200;
}
.rules-container {
  position: relative;
  background: var(--primary);
  width: calc(80% - 4rem);
  height: calc(80% - 4rem);
  left: 10%;
  top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
  border-radius: 1.2rem;
}
.rules-container h1,
.rules-container p,
.rules-container ul,
.rules-container li {
  margin: 0;
  color: var(--font2-color);
  padding: 0;
}
.rules-container h1 {
  font-size: 1.7rem;
  text-align: center;
  margin-bottom: 2rem;
}
.rules-container p {
  font-size: 1.2rem;
}
.rules-container ul {
  padding: 0 0 0 1.5rem;
  margin-top: 2rem;
}
.rules-container li {
  font-size: 1.2;
  margin-bottom: 1rem;
  line-height: 1.5rem;
}
.rules-container img {
  position: relative;
  margin: 1rem auto;
  border-radius: 0.5rem;
  max-width: calc(100% - 2rem);
  width: 40rem;
}
.rules-start-btn-container {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 3rem 0 2rem 0;
}
.rules-wrapper ::-webkit-scrollbar {
  width: 1.2rem;
}

.rules-wrapper ::-webkit-scrollbar-track {
  background: #333;
  border-radius: 0 12rem 12rem 0;
}

.rules-wrapper ::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 0 12rem 12rem 0;
}

.rules-wrapper ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
@media only screen and (max-width: 700px), screen and (max-height: 700px) {
  html {
    font-size: 15px;
  }
}
@media only screen and (max-width: 600px), screen and (max-height: 600px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 500px), screen and (max-height: 500px) {
  html {
    font-size: 13px;
  }
}
@media only screen and (max-width: 400px), screen and (max-height: 400px) {
  html {
    font-size: 12px;
  }
}
