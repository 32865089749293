.error-conatiner {
  position: fixed;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  background-color: #222;
}
.error-header {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.error-header h1,
.error-header h2 {
  color: var(--font2-color);
  margin: 0;
}
.error-header h1 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.error-header h2 {
  font-size: 1.6rem;
}
.error-content {
  flex: 7;
  position: relative;
}
.error-content .earth {
  font-size: 6rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.error-content .earth img {
  width: 10rem;
}
.error-content .astronaut {
  position: absolute;
  margin-left: 50%;
  margin-top: 50%;
}
.error-content .astronaut img {
  width: 2.5rem;
  position: absolute;
}
.error-footer {
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
}
.error-footer div {
  display: flex;
  justify-content: space-evenly;
  height: 3rem;
}
