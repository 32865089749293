.game-over-container {
  position: fixed;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
.game-over-results-container {
  position: relative;
  width: 50rem;
  max-width: 98%;
  height: 65%;
  margin: auto;
  display: flex;
  flex-direction: column;
  top: 15%;
}
.game-over-podium-container {
  display: flex;
  height: 10rem;
}
.game-over-overlay-bkg {
  position: fixed;
  left: -1vw;
  top: -1vh;
  width: 102vw;
  height: 102vh;
  background-color: #22222299;
  z-index: 0;
}
.game-over-podium-container div {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.game-over-podium-stand {
  background-color: #23282e;
  display: flex;
  justify-content: center;
  color: var(--font2-color);
  font-size: 1.5rem;
  font-weight: bold;
}
.game-over-podium-name-display {
  display: flex;
  justify-content: flex-end;
  color: var(--font2-color);
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.game-over-podium-stand-container-2 .game-over-podium-name-display {
  flex: 5;
}
.game-over-podium-stand-container-2 .game-over-podium-stand {
  flex: 5;
  border-radius: 0.5rem 0 0 0;
  font-size: 2.25rem;
}
.game-over-podium-stand-container-1 .game-over-podium-name-display {
  flex: 3;
}
.game-over-podium-stand-container-1 .game-over-podium-stand {
  flex: 7;
  border-radius: 0.5rem 0.5rem 0 0;
  font-size: 3rem;
  z-index: 1;
}
.game-over-podium-stand-container-3 .game-over-podium-name-display {
  flex: 6;
}
.game-over-podium-stand-container-3 .game-over-podium-stand {
  flex: 4;
  border-radius: 0 0.5rem 0 0;
  font-size: 1.75rem;
}
.game-over-bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.game-over-player-list-container {
  overflow-y: auto;
  padding-top: 0.75rem;
  flex: 1;
}
.game-over-player-list-container ul {
  padding: 0;
  text-align: center;
}
.game-over-player-list-container ul li {
  font-size: 1.4rem;
  color: var(--font2-color);
  font-weight: bold;
  margin-bottom: 1rem;
}
.game-over-footer {
  padding: 1rem;
  text-align: center;
}
.game-over-bottom-container ::-webkit-scrollbar {
  width: 0.7rem;
}
.game-over-bottom-container ::-webkit-scrollbar-track {
  background: #333;
  border-radius: 0.5rem;
}
.game-over-bottom-container ::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 0.5rem;
}
.game-over-bottom-container ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
