.user-game-container {
  display: flex;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background-color: #222;
}
.user-round-info,
.user-time-info {
  position: fixed;
  margin: 0;
  background-color: var(--primary);
  color: var(--font2-color);
  font-size: 1.3rem;
  border-radius: 0 0 2rem 0;
  padding: 1.5rem;
  min-width: 8rem;
  text-align: center;
  top: 0;
}
.user-time-info-loading {
  padding: 1rem;
}
.user-round-info {
  left: 0;
  border-radius: 0 0 3rem 0;
}
.user-time-info {
  right: 0;
  border-radius: 0 0 0 3rem;
}
.user-time-info .small-loading-eath {
  font-size: 1.9rem;
}
.user-time-info span {
  margin: -0.45rem 0 0 0.1rem;
  position: absolute;
  font-size: 1.1rem;
}
.game-map-container {
  position: fixed;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  z-index: 0;
}
.loading-game-starting-container {
  position: relative;
  text-align: center;
  width: 100%;
  height: 90%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.loading-game-starting-msg {
  font-size: 2rem;
  color: var(--font2-color);
}
.user-round-score-container {
  position: fixed;
  bottom: -15%;
  left: 50%;
  transition: 0.5s ease-in-out;
  transform: translateX(-50%);
  background: var(--primary);
  z-index: 1;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  text-align: center;
}
.user-round-score-container h2 {
  margin: 0;
  font-size: 1.4rem;
  color: var(--font2-color);
  letter-spacing: 0.1rem;
  line-height: 2rem;
}
